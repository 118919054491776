import * as React from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

export default function CreateCopoun() {
  const [age, setAge] = React.useState("");
  const [value, setValue] = useState(null);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Paper elevation={3} sx={{ marginRight: "15%", marginLeft: "15%" }}>
        <Box sx={{ padding: 5 }}>
          <Typography variant="h3" align="center" gutterBottom sx={{ paddingBottom: 5 }}>
            Create a Coupon
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                Coupon Name
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                required
                id="name"
                name="name"
                label="Coupon Name"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                Coupon Code
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                required
                id="name"
                name="name"
                label="Coupon Code"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                Coupon Start Date
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                required
                id="Coupon Start Date"
                name="Coupon Start Date"
                label="Coupon Start Date"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
              />

              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={value}
                  onChange={setValue}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider> */}
            </Grid>

            <Grid item xs={12} sm={4}>
              <InputLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 700,
                }}
              >
                Coupon End Date
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                required
                id="Coupon End Date"
                name="Coupon End Date"
                label="Coupon End Date"
                fullWidth
                size="small"
                autoComplete="off"
                variant="outlined"
              />
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={value}
                  onChange={setValue}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider> */}
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={5} />
            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                sx={{ color: "yellow", width: "272px", margin: "5px" }}
                onClick={() => navigate(-1)}
              >
                Go Back to the User Page
              </Button>
              <Button variant="contained" sx={{ color: "yellow" }}>
                Create
              </Button>
            </Grid>
            <Grid item xs={12} sm={5} />
          </Grid>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
