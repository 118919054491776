/**

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { Button, CardContent, Icon, Modal, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";


function ManageUserAccounts() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const navigate = useNavigate();

  // function to create a products
  const add = () => {
    console.log("clicked");
    navigate("/create-user")
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  pt={2}
                  px={2}
                  display="flex"
                  variant="gradient"
                  borderRadius="lg"
                  coloredShadow="info"
                  bgColor="info"
                  justifyContent="space-between"
                  alignItems="center"
                  paddingBottom="15px"
                >
                  <MDTypography variant="h6" color="white">
                    Manage Users Accounts
                  </MDTypography>
                  {/* <Button variant="contained" onClick={add}>Create a New User</Button> */}
                  <MDButton variant="gradient" color="dark" onClick={add}>
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;Create a New User
                  </MDButton>
                </MDBox>

                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default ManageUserAccounts;
