/**

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { Button, CardContent, Icon, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

//Table :

import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function toInch(cm) {
  return (cm / 2.54).toFixed(2);
}

function toInchRange(from, to) {
  return `${toInch(from)} - ${toInch(to)}`;
}

function ProductManagements() {
  // const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const navigate = useNavigate();

  // function to create a products
  const add = () => {
    console.log("clicked");
    navigate("/create-product");
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const rows = [
    createData("Frame 1", "73"),
    createData("Frame 2", "23"),
    createData("Frame 3", "63"),
    createData("Frame 4", "73"),
  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                pt={2}
                px={2}
                display="flex"
                variant="gradient"
                borderRadius="lg"
                coloredShadow="info"
                bgColor="info"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom="15px"
              >
                <MDTypography variant="h6" color="white">
                  Manage Products
                </MDTypography>
                {/* <Button variant="contained" onClick={add}>Create a New User</Button> */}
                <MDButton variant="gradient" color="dark" onClick={add}>
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;Create a New Product
                </MDButton>
              </MDBox>

              <MDBox pt={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 450 }} aria-label="simple table">
                    <TableHead>
                      <StyledTableRow className="table-data">
                        <StyledTableCell>Products</StyledTableCell>
                        {/* <StyledTableCell align="right">Cm</StyledTableCell> */}
                        <StyledTableCell>Price sq.cm(SAR)</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <StyledTableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <StyledTableCell component="td" scope="row" className="common-50">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="left" className="common-50">
                            {row.calories}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">
                            {row.fat}
                          </StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductManagements;
